import { useCallback, useEffect } from 'react';
import { useBulkAddRolesMutation } from '@/entities/admin-app/user/api';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { universalNotification } from '@/lib/utils/notification';
import { useTranslation } from 'react-i18next';
import { rolesApi } from '@/entities/admin-app/roles/api';

export const useAddRoles = (selection: number[]) => {
  const { t } = useTranslation();

  const [fetchAddRoles, resultAddRoles] = useBulkAddRolesMutation();

  const handleAddRoles = useCallback(
    async (selection: number[], roleIds: number[]) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];
      const roleIdsUnique = new Set(roleIds);

      try {
        const res = await fetchAddRoles({
          UserIds: numbersSelection,
          RoleIds: [...roleIdsUnique]
        });

        if (!Object.keys(res).includes('error')) {
          clearApiCacheByTags(usersApi, ['Users']);
          clearApiCacheByTags(rolesApi, ['Roles']);
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [selection]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultAddRoles;
    const _errorStatus = (error as any)?.data;

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successTitle: t('roles.addSuccess')
    });
  }, [resultAddRoles]);

  return { handleAddRoles };
};
