import React from 'react';
import { Box, TextInput, Text } from '@mantine/core';
import { TFunction } from 'i18next';

interface EmailStepProps {
  form: any;
  serviceEmail: boolean;
  EMAIL_DOMAIN: string;
  classes: any;
  t: TFunction;
}

export const EmailStep = ({ form, serviceEmail, EMAIL_DOMAIN, classes, t }: EmailStepProps) => {
  return (
    <Box className={classes.wrapper}>
      {serviceEmail ? (
        <Box>
          <Box className={classes.titleWrapper}>
            <Text className={classes.subtitle}>Создайте новую электронную почту:</Text>
          </Box>
          <Box className={classes.serviceEmailWrapper}>
            <TextInput
              className={classes.email}
              size="lg"
              radius="md"
              variant="filled"
              name="Email"
              placeholder={t('register.email')}
              {...form.getInputProps('Email')}
            />
            <Text className={classes.emailDomain}>{EMAIL_DOMAIN}</Text>
          </Box>
        </Box>
      ) : (
        <>
          <Box className={classes.titleWrapper}>
            <Text className={classes.subtitle}>Введите существующий адрес электронной почты:</Text>
          </Box>
          <TextInput
            size="lg"
            radius="md"
            variant="filled"
            name="Email"
            placeholder={t('register.email')}
            {...form.getInputProps('Email')}
          />
        </>
      )}
    </Box>
  );
};
