import React, { useCallback } from 'react';
import { Stack, Box, Text, TextInput, Group, Button } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import iconSuccess from '@/assets/icon-mail-success.svg';
import iconError from '@assets/icon-mail-error.svg';
import { useTranslation } from 'react-i18next';
import { LoginWraper } from '@/components/login';
import { z } from 'zod';
import { useStyles } from './styles';
import { useResetPasswordMutation } from '@/entities/admin-app/user/api';
import { emailRegExp, getValidateErrors } from '@/lib/utils/validation';

interface FormValues {
  Email: string;
  AdditionalEmail?: string;
  IsEnabled: boolean;
}

export const RequestResetPassword = () => {
  const { t } = useTranslation();

  const [fetchResetPassword, { isError, isSuccess, isLoading }] = useResetPasswordMutation();
  const { classes } = useStyles();

  const enabledScheme = z
    .object({
      IsEnabled: z.literal(true),
      Email: z.string().regex(emailRegExp, { message: t('requestResetPassword.incorrectEmail') }),
      AdditionalEmail: z
        .string()
        .regex(emailRegExp, { message: t('requestResetPassword.incorrectEmail') })
    })
    .refine((data) => data.Email !== data.AdditionalEmail, {
      message: t('validation.emailsShouldNotBeTheSame'),
      path: ['AdditionalEmail']
    });

  const disabledScheme = z.object({
    IsEnabled: z.literal(false),
    Email: z.string().regex(emailRegExp, { message: t('requestResetPassword.incorrectEmail') }),
    AdditionalEmail: z.string().optional().nullable()
  });

  const validateScheme = z.union([enabledScheme, disabledScheme]);

  const form = useForm<FormValues>({
    validateInputOnChange: true,
    initialValues: { Email: '', AdditionalEmail: '', IsEnabled: false },
    validate: (values: FormValues): FormErrors => getValidateErrors(validateScheme, values)
  });

  const handleSubmit = async (values: FormValues): Promise<void> => {
    try {
      const data = {
        Email: values.Email,
        AdditionalEmail: values.AdditionalEmail
      };
      await fetchResetPassword(data);
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const handleShowAdditionalEmailField = useCallback(() => {
    form.setFieldValue('IsEnabled', true);
  }, []);

  return (
    <LoginWraper
      title={!isSuccess && !isError ? t('requestResetPassword.title') : ''}
      text={
        !isSuccess && !isError
          ? t(
              `requestResetPassword.${form?.values?.IsEnabled ? 'withAdditionalEmailText' : 'text'}`
            )
          : ''
      }
    >
      {!isSuccess && !isError && (
        <Box component="form" onSubmit={form.onSubmit(handleSubmit)} className={classes.controls}>
          <Stack spacing="xs">
            <TextInput
              size="lg"
              radius="md"
              name="Email"
              variant="filled"
              placeholder={t('requestResetPassword.email')}
              {...form.getInputProps('Email')}
            />
            {form?.values?.IsEnabled ? (
              <TextInput
                size="lg"
                radius="md"
                name="Email"
                variant="filled"
                placeholder={t('requestResetPassword.additionalEmail')}
                {...form.getInputProps('AdditionalEmail')}
              />
            ) : null}

            {!form?.values?.IsEnabled ? (
              <Button
                variant="subtle"
                className={classes.subtleButton}
                size="md"
                onClick={() => handleShowAdditionalEmailField()}
              >
                {t('requestResetPassword.haveNoAccessToEmail')}
              </Button>
            ) : null}

            <Group position="left">
              <Button
                size="lg"
                w="100%"
                type="submit"
                disabled={!form.isValid()}
                loading={isLoading}
              >
                {t('send')}
              </Button>
            </Group>
          </Stack>
        </Box>
      )}
      <>
        {isSuccess && (
          <Stack>
            <Group position="center" spacing="xs">
              <img src={iconSuccess} alt="icon" className={classes.icon} />
            </Group>
            <Text className={classes.desc}>{t('requestResetPassword.successText')}</Text>
            <Group position="center">
              <Button radius="md" w="100%" size="lg" component="a" href="/login">
                {t('requestResetPassword.backMain')}
              </Button>
            </Group>
          </Stack>
        )}
        {isError && (
          <Stack>
            <Group position="center" spacing="xs">
              <img src={iconError} alt="icon" className={classes.icon} />
            </Group>
            <Text className={classes.desc}>{t('requestResetPassword.errorText')}</Text>
            <Group position="center">
              <Button radius="md" w="100%" size="lg" component="a" href="/password-reset">
                {t('requestResetPassword.tryAgain')}
              </Button>
            </Group>
          </Stack>
        )}
      </>
    </LoginWraper>
  );
};
