import { getUserInitials } from '@/lib/utils';
import { Avatar, AvatarProps } from '@mantine/core';
import { useAvatarColor } from '@sinups/agg';
import { forwardRef } from 'react';

type User = {
  Id?: number;
  FirstName?: string | null;
  SurName?: string | null;
};

interface IUserAvatarProps extends AvatarProps {
  user?: User;
  src?: string | null;
  userId?: string | number;
  notClickable?: boolean;
}
export const UserAvatar = forwardRef<HTMLDivElement, IUserAvatarProps>(
  ({ user, src, userId, notClickable, ...props }, ref) => {
    const AvatarColor = useAvatarColor(userId?.toString() ?? user?.Id?.toString() ?? '');
    const userInitials = getUserInitials(user);

    return (
      <Avatar
        {...props}
        src={src}
        radius={60}
        tt="uppercase"
        ref={ref}
        sx={{
          zIndex: 1,
          cursor: notClickable ? 'default' : 'pointer',
          '.mantine-Avatar-placeholder': {
            background: `${AvatarColor}`,
            color: '#fff',
            border: 0
          }
        }}
      >
        {userInitials}
      </Avatar>
    );
  }
);

UserAvatar.displayName = 'UserAvatar';
