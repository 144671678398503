import React, { FC } from 'react';
import { Badge, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { getIndicatorByUserStatus } from '../../../../utils';
import { IUserInfo } from '@/entities/admin-app/user';
import OverflowTip from '@components/OverflowTip';
import { UserAvatar } from '@/components/user-avatar';
import { useTranslation } from 'react-i18next';

interface IPhotoInfoProps {
  userInfoCommon?: IUserInfo;
}

export const PhotoInfo: FC<IPhotoInfoProps> = ({ userInfoCommon }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { fullLabel, colorBadge } = getIndicatorByUserStatus(userInfoCommon?.Status ?? 0, t);

  return (
    <Box className={classes.photo}>
      <UserAvatar
        user={userInfoCommon?.Common}
        userId={userInfoCommon?.Id}
        size={124}
        src={userInfoCommon?.Avatar}
        notClickable
      />
      <Badge className={classes.badge} variant="light" color={colorBadge}>
        <Text>{fullLabel}</Text>
      </Badge>
      <Box className={classes.container} maw="100%">
        <OverflowTip className={classes.name}>{`${userInfoCommon?.Common?.SurName || ''} ${
          userInfoCommon?.Common?.FirstName || ''
        } ${userInfoCommon?.Common?.MiddleName || ''}`}</OverflowTip>
        <OverflowTip className={classes.email}>
          {userInfoCommon?.Credentials?.Email || ''}
        </OverflowTip>
      </Box>
    </Box>
  );
};
