import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { usersActions } from '@/entities/admin-app/users';
import { userInfoActions } from '@/entities/admin-app/user';
import { directoriesActions } from '@/entities/admin-app/directories';
import { groupsActions } from '@/entities/admin-app/groups';
import { rolesActions } from '@/entities/admin-app/roles';
import { quotasActions } from '@/entities/admin-app/quotas';
import { statisticsActions } from '@/entities/admin-app/statistics';
import { filialsActions } from '@/entities/admin-app/filials';
import { commonSettingsActions } from '@/entities/admin-app/commonSettings';
import { licenseActivationsActions } from '@/entities/admin-app/activations';
const actions = {
  ...userInfoActions,
  ...usersActions,
  ...directoriesActions,
  ...groupsActions,
  ...quotasActions,
  ...statisticsActions,
  ...filialsActions,
  ...rolesActions,
  ...commonSettingsActions,
  ...licenseActivationsActions
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
