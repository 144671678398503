import { FC, SyntheticEvent } from 'react';
import { Box, Indicator } from '@mantine/core';
import { useStyles } from './styles';
import { IUserTableData } from '@/entities/admin-app/users';
import { getIndicatorByUserStatus } from '@/containers/pages/users/utils';
import { UserStatusEnum } from '@/types/enums/user';
import OverflowTip from '@/components/OverflowTip';
import { UserAvatar } from '@/components/user-avatar';
import { useTranslation } from 'react-i18next';

interface IUserListItemProps {
  item: IUserTableData;
  userId?: number;
  handleClick: (event: SyntheticEvent<HTMLDivElement>) => void;
  checkedUsers?: number[];
  multiple?: boolean;
  usersList?: IUserTableData[];
}

export const UserListItem: FC<IUserListItemProps> = ({
  item,
  handleClick,
  userId,
  checkedUsers,
  multiple,
  usersList
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { color } = getIndicatorByUserStatus(item.Status, t);

  const isDisabled = usersList?.some((user) => user.Id === item.Id);

  return (
    <Box
      data-id={item.Id}
      className={cx(
        classes.user,
        {
          [classes.selectedUser]:
            (userId === item.Id && !multiple) || (multiple && checkedUsers?.includes(item.Id))
        },
        { [classes.disabledUser]: isDisabled && multiple }
      )}
      onClick={!isDisabled ? handleClick : undefined}
    >
      <Indicator
        label={item?.Status === UserStatusEnum.Ban ? 'B' : ''}
        size={item?.Status === UserStatusEnum.Ban ? 13 : 0}
        offset={6}
        position="bottom-start"
        color={color}
        sx={{
          '& .mantine-Indicator-indicator': {
            fontSize: '7px',
            fontWeight: 600
          }
        }}
      >
        <UserAvatar size={40} src={item.Avatar} user={item} />
      </Indicator>
      <Box className={classes.content}>
        <OverflowTip size="sm" weight={400}>
          {`${item.Surname} ${item.FirstName || ''}`}
        </OverflowTip>

        <OverflowTip color="dimmed" size="xs">
          {item.Email}
        </OverflowTip>
      </Box>
    </Box>
  );
};
