import { Button, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons';
import { useStyles } from './styles';
import { useEffect } from 'react';
import { RolesCreateForm } from './form';
import { Modal } from '@/components/modal';
import { useTranslation } from 'react-i18next';

export const CreateRoles = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  return (
    <>
      <Button
        data-cy="createRole"
        size="md"
        leftIcon={<IconPlus />}
        className={classes.createButton}
        onClick={open}
        styles={{
          leftIcon: { marginRight: '0' }
        }}
      >
        <Text className={classes.createButtonText}>{t('create')}</Text>
      </Button>

      <Modal opened={opened} onClose={close} title={t('roles.create')} zIndex={1002}>
        <RolesCreateForm close={close} />
      </Modal>
    </>
  );
};
