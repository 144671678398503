import { useCallback, useEffect } from 'react';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { universalNotification } from '@/lib/utils/notification';
import { useBulkChangeFilialMutation } from '@/entities/admin-app/user/api';

export const useChangeFilial = (selection: number[]) => {
  const [fetchChangeFilial, resultChangeFilial] = useBulkChangeFilialMutation();

  const changeFilial = useCallback(
    async (selection: number[], CustomerId: number) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];

      try {
        const res = await fetchChangeFilial({
          UserIds: numbersSelection,
          CustomerId: CustomerId
        });

        if (!Object.keys(res).includes('error')) {
          clearApiCacheByTags(usersApi, ['Users']);
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [selection]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeFilial;
    const _errorStatus = (error as any)?.data;

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successTitle: 'Подразделение успешно обновлено'
    });
  }, [resultChangeFilial]);

  return { changeFilial };
};
