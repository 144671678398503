import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useDeleteRolesMutation } from '@/entities/admin-app/roles/api';
import { themeColors } from '@/theme/colors';
import { useActions } from '../redux/action';
import { PanelsEnum } from '@/types/enums/roles';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { useAppSelector } from '../redux/redux';
import { useTranslation } from 'react-i18next';

export const useDeleteRoles = () => {
  const { t } = useTranslation();
  const [fetchDeleteGroup, { isSuccess }] = useDeleteRolesMutation();
  const { setSelectedRolesId, setSelectedPanel } = useActions();
  const { filters: rolesFilter } = useAppSelector((state) => state.roles);
  const { setRolesTableFilters } = useActions();

  const handleConfirm = async (selection: number) => {
    try {
      const res = await fetchDeleteGroup(selection);
      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err) {
      console.log('fetchDeleteGroup', err);
    }
  };

  const openDeleteRoles = (selection: number) => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: (
          <Box mb={24}>
            {t('roles.deleteWarningText')}
            {/*Вы уверены что хотите удалить роль? Пользователи с данными ролями могут потерять доступ*/}
            {/*к данным, к которым были предоставлены права.*/}
          </Box>
        ),
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%'
          }
        },
        labels: { confirm: t('continue'), cancel: t('cancel') },
        onConfirm: () => handleConfirm(selection)
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setRolesTableFilters({
        ...rolesFilter,
        Offset: 0
      });

      showNotification({
        title: t('roles.deleteSuccess'),
        message: '',
        autoClose: true,
        color: 'green'
      });

      setSelectedRolesId(0);

      // if (setActivePanel) {
      setSelectedPanel(PanelsEnum.generalInformation);
      // }
    }
  }, [isSuccess]);

  return { openDeleteRoles };
};
