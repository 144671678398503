import React, { FC, useState, useEffect } from 'react';
import { Button } from '@mantine/core';
import { IColumnData } from '@components/simple-table/types';
import { IStatisticsInitialFilters } from '@/types/enums/statistics';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';

interface IDownloadButtonProps {
  text: string | null;
  filename: string | null;
  columns: IColumnData[];
  csvData: any;
  actionsFilters: IStatisticsInitialFilters;
  getCsvData: any;
  isLoading: boolean | null;
}
export const CSVLinkComponent: FC<IDownloadButtonProps> = ({
  text,
  filename,
  columns,
  csvData,
  actionsFilters,
  getCsvData,
  isLoading
}) => {
  const { t } = useTranslation();
  const [download, setDownload] = useState(false);
  const csvLink = React.createRef<any>();
  interface CsvHeadersInterface {
    label: string | number;
    key: string;
  }

  const csvHeaders: CsvHeadersInterface[] | any = columns.map((column) => ({
    label: column.title,
    key: column.dataIndex
  }));

  const getData = async () => {
    await getCsvData(actionsFilters);
    setDownload(true);
  };

  useEffect(() => {
    if (download && csvData) {
      setDownload(false);
      csvLink.current?.link.click();
    }
  }, [csvData, download]);

  return (
    <>
      <Button data-cy="exportStatistics" size="sm" radius={8} mr={16} onClick={getData}>
        {isLoading ? t('statistics.load_csv') : text}
      </Button>
      <CSVLink
        data={csvData ?? ''}
        filename={filename + '.csv'}
        className="hidden"
        ref={csvLink}
        target="_blank"
        headers={csvHeaders}
      />
    </>
  );
};
