import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useGetUsersQuery } from '@/entities/admin-app/users/api';
import { useMediaQuery } from '@mantine/hooks';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { useGetColumns } from './hooks/useGetColumns';
import { useGetUsersFilters } from './hooks/useGetUsersFilters';
import { ErrorBoundary } from '@components/error-boundary';
import { SimpleTable } from '@components/simple-table';
import { IRowData } from '@components/simple-table/types';
import { Layout } from '../../layout';
import { Control } from './components/control';
import UsersFilterList from './components/filter/filter-list';
import { UserInfoMain } from './components/user-info-main';
import { UsersSearch } from './components/search';
import { CreateButton } from './components/create-button';
import { useStyles } from './styles';

import {
  convertToFilterConditions,
  transformConditionals,
  transformNumberToUsersSortField,
  transformUsersSortFieldToNumber
} from './utils';
import { IChangeFilterData } from '@/entities/admin-app/users';
import { IFilterMenu } from '@/types/api';

type FilterValue = number[] | Date[];
export type ActiveFilters = Record<number, FilterValue>;

export const Users = () => {
  const { classes } = useStyles();
  const { filtersMenuList } = useGetUsersFilters();
  const {
    setUsers,
    setUsersTableFilters,
    setSelectedAllUsers,
    setSelectedUsersIds,
    setSelectedUserId
  } = useActions();
  const {
    users,
    total,
    filters: usersFilter,
    selected: selectedUsers,
    columnVisibility
  } = useAppSelector((state) => state.users);

  const scrollRef = useRef<any>(null);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const [rowData, setRowData] = useState<IRowData[]>([]);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(() =>
    usersFilter?.FilterConditions ? transformConditionals(usersFilter?.FilterConditions) : {}
  );
  const [usersFilters, setUsersFilters] = useState<IFilterMenu[]>(filtersMenuList);
  const [sortParams, setSortParams] = useState<{
    SortOrder: number;
    SortField: number;
  }>({
    SortOrder: usersFilter.SortOrder,
    SortField: usersFilter.SortField
  });

  useEffect(() => {
    if (filtersMenuList.length) {
      setUsersFilters(filtersMenuList);
    }
  }, [filtersMenuList]);

  const { data, error, isLoading, isFetching } = useGetUsersQuery({
    Count: 20,
    Offset: usersFilter.Offset,
    SortOrder: usersFilter.SortOrder,
    SortField: usersFilter.SortField,
    Filters: {},
    Query: usersFilter.Query,
    FilterConditions: usersFilter.FilterConditions
  });

  const handleSelectedAll = () => setSelectedAllUsers(rowData.map((object) => object.Id));

  const handleSelected = (event: SyntheticEvent<HTMLInputElement>) => {
    const id = Number(event.currentTarget.dataset.id);
    if (id) {
      setSelectedUsersIds(id);
    }
  };

  const { columns, setColumns, simpleColumns } = useGetColumns(
    handleSelected,
    setRowData,
    columnVisibility,
    scrollRef
  );

  const handleRowClick = useCallback((id: number) => {
    setSelectedUserId(id);
  }, []);

  const handleLoadMore = () => {
    setUsersTableFilters({
      ...usersFilter,
      Offset: usersFilter.Offset + 20
    });
  };

  const handleFilterChange = useCallback(
    ({ Type, value }: IChangeFilterData) => {
      setActiveFilters((PrevFilters) => {
        const updatedData = { ...PrevFilters };
        if (Array.isArray(value)) {
          updatedData[Type] = value;
        } else {
          if (typeof value === 'number') {
            const array = (updatedData[Type] as number[]) || [];
            updatedData[Type] = array.includes(value)
              ? array.filter((val) => val !== value)
              : [...array, value];
          }
        }

        const updatedFilters = { ...PrevFilters, ...updatedData };
        setUsersTableFilters({
          ...usersFilter,
          FilterConditions: convertToFilterConditions(updatedFilters),
          Offset: 0
        });
        scrollRef.current.el.scrollTo(0, 0);
        return updatedFilters;
      });
    },
    [usersFilters, activeFilters]
  );

  const resetActiveFilterByType = useCallback((type: number) => {
    setActiveFilters((prevNumbers) => {
      const updatedNumbers = { ...prevNumbers };
      delete updatedNumbers[type];

      setUsersTableFilters({
        ...usersFilter,
        FilterConditions: convertToFilterConditions(updatedNumbers),
        Offset: 0
      });

      return updatedNumbers;
    });
  }, []);

  const resetAllActiveFilters = useCallback(() => {
    setActiveFilters({});
    setUsersTableFilters({
      ...usersFilter,
      FilterConditions: [],
      Offset: 0,
      Query: ''
    });
  }, []);
  const handleSortTable = (payload: any) => {
    setSortParams({
      SortOrder: payload.SortOrder === true ? 1 : 0,
      SortField: transformUsersSortFieldToNumber(payload.SortField)
    });
  };

  useEffect(() => {
    if (data) {
      const usersData = usersFilter.Offset > 0 ? [...users, ...data.Users] : data?.Users;
      setUsers({ ...data, Users: usersData });
    }
  }, [data]);

  useEffect(() => {
    setRowData(users || []);
  }, [users]);

  useEffect(() => {
    setSelectedUserId(0);
    return () => {
      resetAllActiveFilters();
    };
  }, []);

  useEffect(() => {
    setUsersTableFilters({
      ...usersFilter,
      Offset: 0,
      SortField: sortParams?.SortField,
      SortOrder: sortParams?.SortOrder
    });
  }, [sortParams]);

  return (
    <ErrorBoundary componentTitle="Users page">
      <Layout>
        <Box className={classes.container}>
          <Box className={classes.searchWrapper}>
            <UsersSearch />
            <CreateButton />
          </Box>
          <UsersFilterList
            handleFilterReset={resetActiveFilterByType}
            handleResetFilters={resetAllActiveFilters}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
            filtersMenuList={usersFilters}
          />
          <Control
            columns={columns}
            rowData={rowData}
            selection={selectedUsers || []}
            toggleAll={handleSelectedAll}
            setColumnsData={setColumns}
            setRowData={setRowData}
            simpleColumns={simpleColumns}
            scrollRef={scrollRef}
          />

          <SimpleTable
            dataCy="userTable"
            sort={{
              SortOrder: sortParams.SortOrder !== 0,
              SortField: transformNumberToUsersSortField(sortParams.SortField)
            }}
            setSort={handleSortTable}
            allDataLength={total}
            rowData={rowData}
            setRowData={setRowData}
            columns={columns}
            height={`calc(100vh - ${isMobile ? '320px' : '296px'})`} //  move height to const
            minWidth={700}
            width="calc(100vw - 48px)"
            selection={selectedUsers}
            handleRowClick={handleRowClick}
            getNextData={handleLoadMore}
            error={error}
            scrollRef={scrollRef}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        </Box>
        <Outlet />
        <UserInfoMain setRowData={setRowData} />
      </Layout>
    </ErrorBoundary>
  );
};
