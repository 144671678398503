import { Menu, ActionIcon, Box } from '@mantine/core';
import { IconGridDots } from '@tabler/icons';
import { useAppSelector } from '@/hooks/redux/redux';

import { getMenu } from './utils';
import { ModuleSettings } from '@/entities/admin-app/auth';

import { NoImage } from '@/assets/icons';
import { themeColors } from '@/theme/colors';

export const AppsMenu = () => {
  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings
  ) as ModuleSettings;
  const menu = modules ? getMenu(modules) : [];

  return menu.length > 0 ? (
    <Menu position="bottom-end">
      <Menu.Target>
        <ActionIcon>
          <IconGridDots size={22} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown miw={150}>
        {menu.map(
          (item, index) =>
            item.url &&
            item.label && (
              <Menu.Item
                key={index}
                component="a"
                href={item.url}
                fz={14}
                target="_blank"
                icon={
                  item.iconSrc ? (
                    <Box component="img" src={item.iconSrc} height={24} />
                  ) : (
                    <NoImage stroke={themeColors.brandGray[0]} />
                  )
                }
              >
                {item.label}
              </Menu.Item>
            )
        )}
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
