import { FC, useState } from 'react';
import { Button, Flex, Text } from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons';
import { SelectUserModal } from '@components/modal/select-user';
import { IUserTableData } from '@/entities/admin-app/users';
import { useTranslation } from 'react-i18next';
import OverflowTip from '@/components/OverflowTip';
import { useStyles } from '../styles';

interface ITransferData {
  nameButton?: string;
  userId: number;
  onChange: (id: number | null) => void;
  SkipUsersIds?: number[];
}

export const TransferData: FC<ITransferData> = ({ nameButton, userId, onChange, SkipUsersIds }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<IUserTableData | null>(null);

  const handleClick = () => {
    if (selected) {
      onChange(null);
      setSelected(null);
    } else {
      setShowModal(true);
    }
  };
  const buttonContent = selected ? (
    <Flex align="center" onClick={handleClick} className={classes.transferBadge}>
      <OverflowTip fw="normal" fz="sm" mr={4}>{`${selected.Surname || ''} ${
        selected.FirstName || ''
      }`}</OverflowTip>
      <IconX stroke={3} size={12} />
    </Flex>
  ) : (
    <Button
      variant="subtle"
      color="gray"
      h={28}
      px={12}
      c="#101828"
      bg="backgroundGray.6"
      radius="lg"
      onClick={handleClick}
    >
      <Text span mr="xs">
        {nameButton || t('select')}
      </Text>
      <IconPlus opacity={0.6} size={16} />
    </Button>
  );

  const handleSelectUser = (user: IUserTableData) => {
    onChange(user.Id);
    setSelected(user);
    setShowModal(false);
  };

  return (
    <>
      {buttonContent}
      <SelectUserModal
        multipleUsers={false}
        isOpened={showModal}
        title={t('users.transferToUser')}
        userId={userId}
        setSelectedUsers={handleSelectUser}
        onClose={() => setShowModal(false)}
        SkipUsersIds={SkipUsersIds}
      />
    </>
  );
};
