import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    justifyContent: 'space-between',
    position: 'relative'
  },

  hoverItem: {
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      '& .deleteIcon': {
        paddingRight: theme.spacing.sm,
        opacity: 1
      }
    },

    '& .deleteIcon': {
      opacity: 0
    }
  },

  content: {
    width: '85%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing.xs
  },

  info: {
    width: '80%',
    marginLeft: theme.spacing.sm
  },

  deleteIcon: {
    align: 'center',
    paddingRight: theme.spacing.xs
  }
}));
