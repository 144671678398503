import { useNavigate } from 'react-router-dom';
import { Menu, Group, Text, Divider, Stack } from '@mantine/core';
import { IconUserCircle, IconLogout, IconBug, IconSmartHome } from '@tabler/icons';
import { useActions } from '@hooks/redux/action';
import { ITokens, IUser } from '@/entities/admin-app/auth';
import { useLazyLogoutQuery } from '@/entities/admin-app/user/api';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UserAvatar } from '../user-avatar';

interface Props {
  user: IUser;
}

export const UserMenu = ({ user }: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setUserInfo, setUserTokens, setSelectedAllUsers } = useActions();
  const [fetchLogout] = useLazyLogoutQuery();

  const handleProfile = () => navigate(`/profile/${user.Id}`);
  const handleDashboard = () => navigate(ROUTES.dashboard.fullPath);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const handleLogout = async () => {
    setUserInfo({} as IUser);
    setUserTokens({} as ITokens);
    setSelectedAllUsers([]);
    navigate('/login');
    await fetchLogout();
  };

  const avatarSrc = useMemo(() => {
    return user?.HasAvatar
      ? `/api/v2/profile/getAvatar?id=${user.Id}${user.AvatarKey ? `&key=${user.AvatarKey}` : ''}`
      : '';
  }, [user.AvatarKey, user.HasAvatar]);

  return (
    <Menu
      position="bottom-end"
      shadow="md"
      transitionProps={{ transition: 'pop-top-right', duration: 150 }}
    >
      <Menu.Target>
        <UserAvatar data-cy="profileAvatar" user={user} src={avatarSrc} size={isMobile ? 35 : 42} />
      </Menu.Target>
      <Menu.Dropdown miw={320} maw={400}>
        <Group px="sm" pt={6} spacing={10}>
          <UserAvatar
            user={user}
            src={user?.HasAvatar ? `/api/v2/profile/getAvatar?id=${user.Id}` : null}
            size={50}
          />
          <Stack spacing={0}>
            <Text fz={18} className={classes.truncate}>
              {user.Surname ?? '-'} {user.FirstName ?? '-'}
            </Text>
            <Text fz={14} c="dimmed" className={classes.truncate}>
              {user.Email}
            </Text>
          </Stack>
        </Group>
        <Divider mt="xs" mb={6} />
        <Menu.Item
          component="a"
          data-cy="homePageBtn"
          icon={<IconSmartHome stroke={1.5} size={20} />}
          px="sm"
          onClick={handleDashboard}
        >
          {t('home')}
        </Menu.Item>
        <Menu.Item
          component="a"
          data-cy="profileBtn"
          icon={<IconUserCircle stroke={1.5} size={20} />}
          px="sm"
          onClick={handleProfile}
        >
          {t('profil')}
        </Menu.Item>
        <Menu.Item
          component="a"
          data-cy="feedbackBtn"
          icon={<IconBug stroke={1.5} size={20} />}
          px="sm"
          target="_blank"
          href="https://r7-space.ru/feedback"
        >
          {t('reportError')}
        </Menu.Item>
        <Menu.Item
          component="a"
          data-cy="logoutBtn"
          onClick={handleLogout}
          icon={<IconLogout stroke={1.5} size={20} />}
          px="sm"
        >
          {t('exit')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
