import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  user: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 0,
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
    cursor: 'pointer',
    marginBottom: '3px',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    }
  },
  content: {
    height: '100%',
    width: '80%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: theme.spacing.sm
  },

  selectedUser: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black
  },

  disabledUser: {
    opacity: '0.5',
    backgroundColor: theme.colors.gray[0],
    pointerEvents: 'none'
  }
}));
