import { Container, Title, Text, Button, SimpleGrid, Box } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { ErrorPageBackgroundImage } from '@assets/icons';
import { useTranslation } from 'react-i18next';

export function NotFound() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goMainPage = () => {
    navigate('/');
  };

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Box className={classes.mobileImage}>
          <ErrorPageBackgroundImage />
        </Box>

        <div>
          <Title className={classes.title}>{t('notFound.error')}</Title>
          <Text className={classes.description} color="dimmed">
            {t('notFound.description')}
          </Text>
          <Button
            variant="outline"
            size="md"
            mt="xl"
            className={classes.control}
            onClick={goMainPage}
          >
            {t('notFound.back')}
          </Button>
        </div>

        <Box className={classes.desktopImage}>
          <ErrorPageBackgroundImage />
        </Box>
      </SimpleGrid>
    </Container>
  );
}
