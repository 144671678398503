import { Button, LoadingOverlay, Select, SelectItem, SimpleGrid } from '@mantine/core';
import { FC, useContext, useEffect } from 'react';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { FormErrors, useForm } from '@mantine/form';
import { ISettingsTimeZones } from '@/entities/admin-app/user';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateSchemeTimeZone } from '@/containers/pages/user/schemes/validate';
import { useUpdateInfo } from '@hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';
import { useGetTimezonesQuery } from '@/entities/admin-app/commonSettings/api';
import { transformTimeZones } from '@utils/transformTimeZones';
import { EditUserInfoContext } from '@/containers/pages';
import { useStyles } from '../../../../styles';

const TimeZones: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { saveUpdate, isLoading } = useUpdateInfo();
  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);

  const { TimeZone } = userInfoCommon;
  const { data: timeZones } = useGetTimezonesQuery();
  const list: SelectItem[] = transformTimeZones(timeZones);

  const userTimeZoneForm = useForm<ISettingsTimeZones>({
    initialValues: {
      Id: null,
      SecondEnabled: false,
      SecondId: null
    },
    validateInputOnChange: true,
    validate: (values: ISettingsTimeZones): FormErrors =>
      getValidateErrors(validateSchemeTimeZone(t), values)
  });

  const errors = userTimeZoneForm.errors;
  const isDirtyForm = userTimeZoneForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData: ISettingsTimeZones = {
    Id: TimeZone?.Id?.toString() || null,
    SecondEnabled: Boolean(TimeZone?.SecondEnabled),
    SecondId: TimeZone?.SecondId || null
  };

  useEffect(() => {
    userTimeZoneForm.setValues(fromData);
    userTimeZoneForm.resetDirty(fromData);
  }, [TimeZone]);

  const handleSubmit = async (values: ISettingsTimeZones) => {
    const data = {
      Id: Number(values?.Id),
      SecondEnabled: Boolean(values.SecondEnabled),
      SecondId: 0
    };
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.TimeZone,
      Data: { [ProfileSectionEnum.TimeZone]: data, Id: currentId }
    });
  };

  return (
    <form onSubmit={userTimeZoneForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.timeZonesSettings')}>
        <SimpleGrid cols={2} w="100%" breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
          <Select
            className={classes.select}
            label={t('profile.timeZone')}
            placeholder={t('profile.timeZone')}
            data={list}
            {...userTimeZoneForm.getInputProps('Id')}
          />
        </SimpleGrid>

        <Button
          type="submit"
          size="sm"
          radius={8}
          disabled={isDisabled}
          mt="md"
          style={{ padding: '0 20px' }}
        >
          {t('profile.save')}
        </Button>

        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};

export default TimeZones;
