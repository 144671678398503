import React, { FC, useContext, useEffect } from 'react';
import { SelectQuotas } from '../../../../../components/Quotaselect';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { EditUserInfoContext } from '@/containers/pages';
import { FormErrors, useForm } from '@mantine/form';
import { Button, LoadingOverlay, Select, SimpleGrid } from '@mantine/core';
import { useAppSelector } from '@/hooks/redux/redux';
import { isAdmin } from '@/lib/utils/access';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateSchemeDataStorageQuota } from '@/containers/pages/user/schemes/validate';
import { IUserQuota } from '@/entities/admin-app/user';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';

const DiskData: FC = () => {
  const { t } = useTranslation();
  const { isLimitedAccess, userInfoCommon, currentId } = useContext(EditUserInfoContext);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  const { Quota, Customer } = userInfoCommon;

  const { saveUpdate, isLoading } = useUpdateInfo();

  const userQuotaForm = useForm<IUserQuota>({
    initialValues: {
      Name: '',
      Id: null
    },
    validateInputOnChange: true,
    validate: (values: IUserQuota): FormErrors =>
      getValidateErrors(validateSchemeDataStorageQuota(t), values)
  });

  const errors = userQuotaForm.errors;
  const isDirtyForm = userQuotaForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    Name: Quota?.Name || '',
    Id: Quota?.Id || null
  };

  useEffect(() => {
    userQuotaForm.setValues(fromData);
    userQuotaForm.resetDirty(fromData);
  }, [Quota]);

  const handleSubmit = async (values: IUserQuota) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Quota,
      Data: { [ProfileSectionEnum.Quota]: !values?.Id ? null : values, Id: currentId }
    });
  };

  return (
    <form onSubmit={userQuotaForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.retentionPolicy')}>
        <SimpleGrid cols={2} w="100%" breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
          {isLimitedAccess ? (
            <Select
              data={[
                {
                  value: userQuotaForm.values.Id?.toString() || '',
                  label: userQuotaForm.values.Name || t('profile.noQuota')
                }
              ]}
              value={`${Quota?.Id || ''}`}
              readOnly={true}
              sx={{ width: '100%' }}
              disabled
            />
          ) : (
            <SelectQuotas
              isLimitedAccess={isLimitedAccess}
              defaultValue={userQuotaForm.values.Id}
              form={userQuotaForm}
              fieldName="Id"
              value={userQuotaForm.values.Id}
              QuotaName={userQuotaForm.values.Name}
              Customer={Customer?.Id}
            />
          )}
        </SimpleGrid>
        {isAdmin(currentUser) && (
          <Button
            type="submit"
            size="sm"
            radius={8}
            disabled={isDisabled}
            mt="md"
            style={{ padding: '0 20px' }}
          >
            {t('profile.save')}
          </Button>
        )}
        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};

export default DiskData;
