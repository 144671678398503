import {
  Box,
  Button,
  Group,
  Pagination,
  SimpleGrid,
  Text,
  Title,
  Tooltip,
  Card,
  rem
} from '@mantine/core';
import { IconDeviceDesktop, IconLink } from '@tabler/icons-react';
import { useStyles } from '../styles';
import { ILicense } from '@/entities/admin-app/activations';
import { IconUpload } from '@tabler/icons';
import { NoDataText } from '@components/not-found/components/no-data-text';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DevicesGroupComponentProps {
  data: ILicense[];
  actionButton: (element: ILicense) => void;
  deleteDevice: (element: any) => void;
  setCurrentPage: (page: number) => void;
  linkClick: (hash: string) => void;
  pageMeta?: any;
  label: string;
  isActiveList?: boolean;
}
export const LicensesList = ({
  data,
  linkClick,
  actionButton,
  setCurrentPage,
  pageMeta,
  label,
  isActiveList
}: DevicesGroupComponentProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Group className={classes.group}>
      <Title className={isActiveList ? classes.groupOtherTitle : classes.groupTitle}>{label}</Title>
      {data.length === 0 && <NoDataText />}
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: 'xs', cols: 1 },
          { maxWidth: 'lg', cols: 2 }
        ]}
        spacing="sm"
        className={classes.cardsContainer}
      >
        {data.map((device: ILicense) => (
          <Card key={device.Id} withBorder className={classes.card}>
            <div>
              <IconDeviceDesktop style={{ width: rem(38), height: rem(38) }} stroke={1} />
            </div>
            <Box ml={16}>
              <Text fw={500}>{device.OperatingSystem} </Text>
              <Group>
                <Text className={classes.productText}>
                  {t('license.product')}: {device.ProductType}
                </Text>
              </Group>
              <Text className={classes.greyText}>
                Устройство: {device.MachineId}{' '}
                {device.InstallationGuid ? (
                  <span className={classes.hash}>
                    {`${device.InstallationGuid?.slice(0, 8)}...`}{' '}
                    <Tooltip withArrow label="Скопировать">
                      <IconLink
                        style={{ width: '16px', height: '16px', marginTop: 5 }}
                        stroke={1}
                        // onHover
                        onClick={(e) => {
                          e.preventDefault();
                          // setOpened(false);
                          linkClick(device.InstallationGuid);
                        }}
                      />
                    </Tooltip>
                  </span>
                ) : null}
              </Text>

              <Text size="sm" c="dimmed">
                {t('license.ipAddress')}: {device.MachineIp}
              </Text>
              <Text size="sm" c="dimmed">
                Host name: {device?.MachineHostname}
              </Text>
              {/*{!device.deactivated && (*/}
              {device.Data && (
                <Text size="sm" c="dimmed">
                  {t('license.activationDate')}: {device.Data}
                </Text>
              )}

              <Text size="sm" c="dimmed">
                {/*Статус: {device.deactivated ? 'Деактивирован' : 'Активирован'}*/}
                Статус: {device.Data ? 'Деактивирован' : 'Активирован'}
              </Text>

              <Button
                className={isActiveList ? classes.deactivateButton : classes.activateButton}
                leftIcon={<IconUpload stroke={1.3} transform="" />}
                variant="subtle"
                size="xs"
                onClick={() => actionButton(device)}
              >
                {isActiveList ? t('license.deactivate') : t('license.activate')}
              </Button>

              {/* <Button
                                className={styles.deactivateButton}
                                leftSection={<IconX className={styles.activateIcon} stroke={1.3} transform="" />}
                                variant="subtle"
                                size="xs"
                                color={'#C92A2A'}
                                onClick={() => deleteDevice(device)}
                            >
                                Удалить
                            </Button> */}
            </Box>
          </Card>
        ))}
      </SimpleGrid>
      {data.length !== 0 && (
        <Box>
          <Pagination
            total={pageMeta.pages}
            onChange={(page) => {
              setCurrentPage(page - 1);
            }}
          />
        </Box>
      )}

      {/*{data.length > 5 && (*/}
      {/*  <Pagination*/}
      {/*    className={classes.paginate}*/}
      {/*    value={0}*/}
      {/*    onChange={(page) => {*/}
      {/*      setCurrentPage(page + 1);*/}
      {/*    }}*/}
      {/*    total={Math.ceil(50)}*/}
      {/*    withControls={false}*/}
      {/*    withEdges*/}
      {/*    size="sm"*/}
      {/*    position="center"*/}
      {/*    siblings={1}*/}
      {/*  />*/}
      {/*)}*/}
    </Group>
  );
};
