import { FC } from 'react';
import { FilterBadge } from '../tabs/audit/components/filter-badge';
import { DateRangeFilter } from '@components/filters/date-range-filter';
import { SearchFilter } from '@components/filters/search-filter';
import { IChangeFilterData, IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { IStatsFilters } from '../../types';
import { IFilterItemsResponse } from '@/entities/admin-app/users';
import { Box } from '@mantine/core';
// import { useGetAuthActionsFilterQuery } from '@/entities/admin-app/statistics/api';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface ILoadMoreProps {
  Type: IFiltersEnumType;
  Count: number;
  Offset: number;
  Query: string;
}

interface IFilterListProps {
  filtersMenuList: IFilterMenu[];
  activeFilters: IStatsFilters;
  onFilterChange: (data: IStatsFilters) => void;
  onFilterReset: (value: IStatsFilters) => void;
  onLoadData: (
    args: ILoadMoreProps,
    opts: { skip: boolean }
  ) => { data?: IFilterItemsResponse; isLoading: boolean };
}

export const FilterList: FC<IFilterListProps> = ({
  filtersMenuList,
  activeFilters,
  onFilterChange,
  onFilterReset,
  onLoadData
}) => {
  const { i18n } = useTranslation();
  const { classes } = useStyles();

  const handleFilterChange = ({ Type, value }: IChangeFilterData) => {
    let typeFilters = activeFilters[Type] ? [...activeFilters[Type]] : [];

    if (Array.isArray(value)) {
      if (value.some((item) => item === null)) {
        typeFilters = [];
      } else {
        typeFilters = value;
      }
    } else {
      if (typeFilters.some((el) => +el === +value)) {
        typeFilters = (typeFilters as (typeof value)[]).filter(
          (val: typeof value) => +val !== +value
        );
      } else {
        typeFilters.push(value);
      }
    }

    onFilterChange({
      ...activeFilters,
      [Type]: typeFilters
    });
  };

  const handleFilterReset = (type: IFiltersEnumType) => {
    const updatedFilters: IStatsFilters = { ...activeFilters };
    delete updatedFilters[type];

    onFilterReset(updatedFilters);
  };

  //TODO Удалить Когда будет исправлено на бэкенде
  const excludedTypes = [16];
  const filteredMenuList = filtersMenuList.filter((filterMenu: { Type: number }) => {
    return !excludedTypes.includes(filterMenu.Type);
  });
  //TODO Вернуть как было когда всё будет исправлено {filtersMenuList.map((filterMenu, index) => {
  return (
    <Box className={classes.filtersListWrapper} py="sm">
      {/* {filtersMenuList.map((filterMenu, index) => { */}
      {filteredMenuList.map((filterMenu, index) => {
        const { Type: filterType } = filterMenu;
        const activeIds = activeFilters[filterType];

        return (
          <FilterBadge
            key={filterMenu?.label + index}
            filterMenu={filterMenu}
            handleFilterReset={handleFilterReset}
            activeIds={activeIds as number[]}
            filterType={filterMenu?.Type}
          >
            {filterType === IFiltersEnumType.DateOfCreateFilter ? (
              <DateRangeFilter
                type={filterType}
                handleChange={handleFilterChange}
                handleFilterReset={handleFilterReset}
                hideCollapse={true}
                activeValue={activeIds as [Date, Date]}
                maxDate={new Date()}
                locale={i18n.language}
              />
            ) : (
              // ToDo fix on backend, now is return on russian name in Item.Name
              <SearchFilter
                handleFilterReset={handleFilterReset}
                activeIds={activeIds as number[]}
                filterType={filterMenu?.Type}
                label={filterMenu?.label}
                list={filterMenu?.Items}
                hideSearch={filterType === IFiltersEnumType.AuthActionTypeFilter}
                hideCollapse={true}
                handleFilterChange={handleFilterChange}
                handleLoadData={onLoadData}
              />
            )}
          </FilterBadge>
        );
      })}
    </Box>
  );
};
