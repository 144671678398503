import { Button, Box, Group, PasswordInput, Text } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { Dispatch, FC } from 'react';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { useStyles } from './styles';
import { useTranslation, Trans } from 'react-i18next';
import { useLoginAs } from '@/containers/pages/users/hooks/useLoginAs';

interface ILoginAsModalProps {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  login?: string;
  loginAs: string;
}

export const LoginAsModalContent: FC<ILoginAsModalProps> = ({ setOpen, loginAs, login }) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const { isFetchingLoginAs, handleLoginAs, isSuccess } = useLoginAs();

  const dataForm = {
    Password: ''
  };

  const validateScheme = z.object({
    Password: z.string().min(1, { message: t('login.errors.emptyPassword') })
  });
  const form = useForm({
    initialValues: dataForm,
    validateInputOnChange: true,
    validate: (values): FormErrors => getValidateErrors(validateScheme, values)
  });

  const isDisabled = Object.keys(form.errors).length !== 0 || !form.isDirty();

  const handleUpdate = () => {
    setOpen(false);
  };

  const onSubmit = async (values: { Password: string }) => {
    await handleLoginAs({
      Login: login || '',
      LoginAs: loginAs,
      Password: values.Password,
      handleUpdate
    });
    if (!isSuccess) {
      form.resetDirty();
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group className={classes.modalRoot} position="center">
        <Box className={classes.modalContainer}>
          <Text mb={theme.spacing.sm}>
            <Trans i18nKey="profile.loginAsHint" values={{ LoginAs: loginAs }} />
          </Text>
          <PasswordInput
            className={classes.title}
            required={true}
            label={t('login.password')}
            placeholder={t('profile.enterPassword')}
            data-autofocus
            {...form.getInputProps('Password')}
          />
        </Box>
        <Box className={classes.modalFooter}>
          <Button loading={isFetchingLoginAs} fullWidth type="submit" disabled={isDisabled}>
            {t('send')}
          </Button>
        </Box>
      </Group>
    </form>
  );
};
