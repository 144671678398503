import { FC, ElementType } from 'react';
import { Link, useLocation, matchRoutes } from 'react-router-dom';
import { Box, Text, NavLink } from '@mantine/core';
import { useStyles } from './styles';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import { ITransformedRoute } from '@/types';

interface ISidebarMenuProps {
  setIsOpen: (isOpen: boolean) => void;
  linksBlocks: { label: string; pages: readonly INavbarMenu[] }[];
  routes: ITransformedRoute[];
}

export const SidebarMenu: FC<ISidebarMenuProps> = ({ setIsOpen, linksBlocks, routes }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [currentRoute] = matchRoutes(routes, location) || [];
  const currentPage = currentRoute?.route?.chunk;

  const handleClickClose = () => {
    return setIsOpen(false);
  };

  return (
    <>
      {linksBlocks.map((block, index) => (
        <Box key={index} mb="sm">
          <Text className={classes.heading} fz="sm" ml="sm">
            {block.label}
          </Text>
          {block.pages.map((item, index) => {
            const isActive = item.value === currentPage;
            const Icon = item.icon as ElementType;
            return (
              <NavLink
                className={classes.navLinkItem}
                key={index}
                label={item.label}
                icon={<Icon stroke={isActive ? '#101828' : '#667085'} />}
                component={Link}
                onClick={() => handleClickClose()}
                to={item.value}
                active={isActive}
              />
            );
          })}
        </Box>
      ))}
    </>
  );
};
