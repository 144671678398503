export enum ICommonSettingsEnum {
  Commands = 1,
  CommonViewSettings = 2
}

export enum IFileAccessModifier {
  Public = 0,
  Protected = 1,
  Private = 2
}

export interface ITeamSettings {
  IsEnabled: boolean;
  Url: string | null;
}
export interface ICommonViewSettings {
  Name: string;
  MainLogoId: number | null;
  BrandColor: string;
  MainLogoUrl: string;
}
export interface ICommonSettings {
  TeamSettings: ITeamSettings;
  CommonViewSettings: ICommonViewSettings;
}

export interface IUpdateCommonSettings {
  Type: ICommonSettingsEnum;
  Data: Partial<ICommonSettings>;
}

export interface IUploadFileRequest {
  file: Blob | null;
  FileAccessModifier?: IFileAccessModifier;
}

export interface IUploadedFileResponse {
  Id: number;
  Name: string;
  Description: string;
  Timestamp: string;
  Date: string;
  Status: number;
  Type: number;
  Size: number;
  MimeType: null | any;
  Url: string;
}
export interface IProductNotificationData {
  UpdateInfo: IProductNotification;
}
export interface IProductNotification {
  UpdateId: string;
  Version: string;
  ReleaseDate: string;
  UpdateDate: string;
  ImageUrl: string;
  Topic: string;
  Text: string;
  UpdateUrl: string;
  IsEnabled: boolean;
}
