import { useCallback } from 'react';
import { clearApiCacheByTags } from '@/entities/helpers';
import { useTranslation } from 'react-i18next';
import { universalNotification } from '@/lib/utils/notification';
import {
  licenseActivationsApi,
  useActivateLicenceMutation
} from '@/entities/admin-app/activations/api';
import { ILicenseCommon } from '@/entities/admin-app/activations';

export const useActivateLicense = () => {
  const { t } = useTranslation();
  const [handleActivateLicense] = useActivateLicenceMutation();
  return useCallback(async (license: ILicenseCommon) => {
    try {
      const res = await handleActivateLicense({
        ...license
      });

      if ('error' in res) {
        universalNotification({
          error: `${(res as any)?.error}`,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          successTitle: t('license.activationSuccess'),
          isSuccess: true,
          isError: false,
          error: null
        });
        clearApiCacheByTags(licenseActivationsApi, ['LicenseList']);
      }
    } catch (err: any) {
      console.warn(t('filials.errors.save'), err);
    }
  }, []);
};
