import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    position: 'relative'
  },
  emailBox: {
    button: {
      marginLeft: '-5px',
      marginRight: '5px'
    }
  }
}));
