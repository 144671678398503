import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  },

  photo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: theme.spacing.md,
    width: '100%'
  },

  name: {
    color: theme.black,
    fontSize: '13px',
    fontWeight: 600,
    marginBottom: theme.spacing.xs,
    wordBreak: 'break-all',
    lineHeight: '22px',
    [theme.fn.largerThan('xs')]: {
      fontSize: '18px'
    }
  },

  email: {
    color: theme.colors.brandGray[0],
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: theme.spacing.xs
  },

  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },

  groupTitle: {
    color: theme.colors.brandGray[0],
    fontSize: '12px'
  },

  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  loginAs: {
    width: '100%'
  },

  loginButton: {
    backgroundColor: theme.colors.brandGray[1],
    color: '#000000',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.colors.dark[1]
    },
    svg: {
      marginRight: '10px'
    }
  }
}));
