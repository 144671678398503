import { ActionIcon, Box, Button, Group, Indicator, Text } from '@mantine/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useStyles } from './styles';
import { AvatarEditModal } from '../../../modal/avatar-edit';
import { EditUserInfoContext } from '../../../index';
import { AvatarWithOverlay } from '@components/avatar-with-overlay';
import { useDeleteAvatarModal } from '../../../hooks/useDeleteAvatar';
import { showNotification } from '@mantine/notifications';
import { IconCircleX } from '@tabler/icons';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { StatusSelect } from '../../../../../components/StatusSelect';
import { LoginAsIcon } from '@/assets/icons';
import { useAppSelector } from '@/hooks/redux/redux';
import { LoginAsModalContent } from '@/containers/pages/users/components/modals/login-as';
import { RoleTypesEnum, UserStatusEnum } from '@/types/enums/user';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { Modal } from '@components/modal';

export const ProfileHeader = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { currentId, userInfoCommon, isLimitedAccess, avatarKey } = useContext(EditUserInfoContext);
  const User = useAppSelector((state) => state?.userInfo?.User);

  const { Avatar, Status, Common, Credentials } = userInfoCommon;
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { openDeleteAvatar, isSuccess } = useDeleteAvatarModal(currentId);
  const [editAvatar, setEditAvatar] = useState<boolean>(false);
  const [openLoginAsModal, setOpenLoginAsModal] = useState(false);

  const fullName = `${Common?.SurName ?? ''} ${Common?.FirstName ?? ''} ${
    Common?.MiddleName ?? ''
  } `;

  const avatarSrc = useMemo(() => {
    if (User?.Id === currentId) {
      return User?.AvatarKey
        ? `/api/v2/profile/getAvatar?id=${User.Id}&key=${User.AvatarKey}`
        : Avatar || '';
    } else
      return avatarKey
        ? `/api/v2/profile/getavatar?id=${currentId}&key=${avatarKey}`
        : Avatar || '';
  }, [User?.AvatarKey, User?.HasAvatar, Avatar, avatarKey]);

  const handleCloseModalEditAvatar = () => {
    setEditAvatar(false);
  };

  const openAvatarModal = () => {
    setEditAvatar(true);
  };

  const handleDeleteAvatar = () => {
    openDeleteAvatar();
  };

  const handleLoginAs = () => {
    setOpenLoginAsModal(true);
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        title: t('profile.avatarDeletedSuccessMessage'),
        message: '',
        autoClose: true,
        color: 'green'
      });
    }
  }, [isSuccess]);

  const canChangeStatus = () => {
    const isLoggedAsSuperUser = User?.Roles.some((role) => role.Type === RoleTypesEnum.SuperUser);
    const hasNoSuperUserRole = !userInfoCommon.Roles?.some(
      (role) => role?.Type === RoleTypesEnum.SuperUser
    );

    return (
      !isLimitedAccess && User?.Id !== currentId && (isLoggedAsSuperUser || hasNoSuperUserRole)
    );
  };

  const showLoginAsButton = () => {
    return (
      User?.Id !== currentId &&
      Status === UserStatusEnum.Active &&
      !userInfoCommon.Roles?.some(
        (role) => role?.Type === RoleTypesEnum.SuperUser || role?.Type === RoleTypesEnum.Admin
      )
    );
  };

  return (
    <SectionTemplate>
      <Box className={classes.root}>
        <Box className={classes.photo}>
          <Indicator
            label={
              Avatar?.length ? (
                <ActionIcon size="md" variant="transparent" onClick={() => handleDeleteAvatar()}>
                  <IconCircleX color="#667085" />
                </ActionIcon>
              ) : null
            }
            size={13}
            offset={isMobile ? 5 : 10}
            position="top-end"
            color="transparent"
          >
            <AvatarWithOverlay
              size={isMobile ? 90 : 124}
              radius={62}
              src={avatarSrc}
              handleClick={openAvatarModal}
            />
          </Indicator>

          <Box className={classes.mainInfo}>
            <Text className={classes.name}>{fullName}</Text>

            <Group position="apart" className={classes.loginAs}>
              <StatusSelect
                editUser={true}
                placeholder={t('profile.chooseStatus')}
                fieldName="Status"
                value={Status}
                disabled={!canChangeStatus()}
              />
              {showLoginAsButton() ? (
                <Button radius="md" className={classes.loginButton} onClick={handleLoginAs}>
                  <LoginAsIcon stroke="#000000" />
                  {t('profile.loginAs')}
                </Button>
              ) : null}
            </Group>
          </Box>
        </Box>

        <AvatarEditModal
          isOpened={editAvatar}
          title={t('profile.profilePhoto')}
          onClose={handleCloseModalEditAvatar}
          id={currentId}
        />
      </Box>
      <Modal
        withinPortal={true}
        opened={openLoginAsModal}
        onClose={() => setOpenLoginAsModal(false)}
        title={<Text fz="md">{t('profile.loginAs')}</Text>}
      >
        <LoginAsModalContent
          setOpen={setOpenLoginAsModal}
          loginAs={Credentials?.Login || ''}
          login={User?.Login}
        />
      </Modal>
    </SectionTemplate>
  );
};
