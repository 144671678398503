import { Box, Group } from '@mantine/core';
import { FC, ReactNode, useMemo } from 'react';
import { MainHeader } from '@components/header';
import { AppsMenu } from '@/components/apps-menu';
import { UserMenu } from '@components/user-menu';
import { useAppSelector } from '@hooks/redux/redux';
import { getHeaderListByRole } from '../pages/users/utils';
import { useStyles } from './styles';
import { isSaaS, isSuperAdmin } from '@/lib/utils/access';
import { useTranslation } from 'react-i18next';

interface ILayoutProps {
  children: ReactNode;
  withoutNavigation?: boolean;
  leftNavbarContent?: ReactNode;
  rightNavbarContent?: ReactNode;
}

export const Layout: FC<ILayoutProps> = ({
  children,
  withoutNavigation = false,
  leftNavbarContent,
  rightNavbarContent
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { User, Settings } = useAppSelector((state) => state.userInfo);
  const isSuperUser = isSuperAdmin(User);
  const isSaaSProduct = isSaaS(Settings);

  const rightSection = useMemo(
    () =>
      !User ? null : (
        <Group spacing="sm">
          <AppsMenu />
          <UserMenu user={User} />
        </Group>
      ),
    [User]
  );

  return (
    <Box className={classes.root}>
      <MainHeader
        list={withoutNavigation ? [] : getHeaderListByRole(t, isSuperUser, isSaaSProduct)}
        rightSection={rightSection}
      />
      <Box className={classes.main}>
        {leftNavbarContent}
        {children}
        {rightNavbarContent}
      </Box>
    </Box>
  );
};
