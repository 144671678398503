import { createStyles } from '@mantine/core';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: { ...modalTheme(theme).container, wordBreak: 'break-word' },
  modalFooter: modalTheme(theme).footer,
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  Content: { width: '100%' },
  title: {
    width: '100%'
  },

  description: {
    width: '100%'
  }
}));
