import { FC, SyntheticEvent } from 'react';
import { ActionIcon, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { CircleCloseIcon } from '@assets/icons';
import { formatDate } from '@/lib/utils/date';
import { IconCircleCheck } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

export interface IConnectionItemProps {
  Id: number;
  SystemName: string;
  Ip: string;
  Date: string;
  terminateConnection: (event: SyntheticEvent<HTMLButtonElement>) => void;
  isCurrent: boolean;
}

export const ConnectionItem: FC<IConnectionItemProps> = ({
  Id,
  SystemName,
  Ip,
  Date,
  terminateConnection,
  isCurrent
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.leftSection}>
        <Box className={classes.group}>
          {SystemName ? (
            <Text className={classes.title}>{SystemName}</Text>
          ) : (
            <Text className={classes.description}>{t('profile.noInformation')}</Text>
          )}
        </Box>
        <Box className={classes.group}>
          <Text className={classes.title}>{Ip}</Text>
          <Text className={classes.description}>
            {formatDate(Date) ?? t('profile.noInformation')}
          </Text>
        </Box>
      </Box>

      <>
        {isCurrent ? (
          <ActionIcon variant="transparent" disabled data-cy="currentConnectionIcon">
            <IconCircleCheck color="green" width="18px" height="18px" />
          </ActionIcon>
        ) : (
          <ActionIcon
            onClick={terminateConnection}
            size="md"
            data-id={Id}
            variant="transparent"
            data-cy="terminateConnectionIcon"
          >
            <CircleCloseIcon />
          </ActionIcon>
        )}
      </>
    </Box>
  );
};
