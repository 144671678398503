import { useCallback, useEffect, useState } from 'react';
import { universalNotification } from '@/lib/utils/notification';
import { useChangeStatusesMutation } from '@/entities/admin-app/user/api';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { UserStatusEnum } from '@/types/enums/user';

export const useChangeStatuses = (editUser?: boolean) => {
  const [num, setNum] = useState(0);
  const [fetchChangeStatus, resultChangeStatuses] = useChangeStatusesMutation();

  const changeStatuses = useCallback(async (selection: number[], userStatus: UserStatusEnum) => {
    const numbersSelection = selection?.map((item) => Number(item)) ?? [];
    setNum(numbersSelection.length);
    try {
      const res = await fetchChangeStatus({
        Ids: numbersSelection,
        status: userStatus
      });
      if (!Object.keys(res).includes('error')) {
        clearApiCacheByTags(usersApi, ['Users']);
      }
    } catch (err: any) {
      console.warn('fetchGetUsers ERROR', err);
    }
  }, []);

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeStatuses;
    const message =
      editUser || num <= 1
        ? 'Статус был успешно обновлен'
        : 'Статусы пользователей успешно обновлены';

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: error,
      successTitle: message
    });
  }, [resultChangeStatuses]);

  return { changeStatuses };
};
