import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Text, Button, Menu, Group, Image } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { useAppSelector } from '@hooks/redux/redux';
import { useActions } from '@hooks/redux/action';
import { useLazyLogoutQuery } from '@/entities/admin-app/user/api';
import { getMenu } from '@/components/apps-menu/utils';
import { ModuleSettings, ITokens, IUser } from '@/entities/admin-app/auth';
import { MainHeader } from '@/components/header';
import { AppsMenu } from '@/components/apps-menu';
import { UserMenu } from '@/components/user-menu';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/modal';
import NoAccessModuleCard from '@components/noAccessModuleCard';
import { useDisclosure } from '@mantine/hooks';
import { UserAvatar } from '@/components/user-avatar';

export const Dashboard = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { setSelectedUserId } = useActions();
  const { setUserInfo, setUserTokens } = useActions();
  const [fetchLogout] = useLazyLogoutQuery();
  const { User } = useAppSelector((state) => state.userInfo);
  const [opened, { open, close }] = useDisclosure(false);
  const [fromValue, setFromValue] = useState<{ moduleTitle?: string; iconSrc?: string } | null>();
  const handleCloseAccessCard = () => {
    close();
  };

  const rightSection = useMemo(
    () =>
      !User ? null : (
        <Group spacing="sm">
          <AppsMenu />
          <UserMenu user={User} />
        </Group>
      ),
    [User]
  );

  const handleClickListItem = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const value = event.currentTarget.dataset.value ?? '';
      switch (value) {
        case 'profile':
          if (User) {
            setSelectedUserId(User?.Id);
            navigate('/profile/' + User?.Id);
          }
          break;
        case 'logout':
          setUserInfo({} as IUser);
          setUserTokens({} as ITokens);
          navigate('/login');
          await fetchLogout();
          break;

        default:
          break;
      }
    },
    [User]
  );

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings
  ) as ModuleSettings;
  const menu = modules ? getMenu(modules) : [];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const fromParam = searchParams.get('from');
    if (fromParam) {
      const fromModule = modules?.ModuleUrls?.find((module) => module?.Name === fromParam);

      if (!fromValue && fromModule?.Title && fromModule?.Icon) {
        setFromValue({ moduleTitle: fromModule?.Title, iconSrc: fromModule?.Icon });
        open();
      }
    }
  }, [location]);

  return (
    <Box className={classes.container}>
      <MainHeader list={[]} rightSection={rightSection} dashboardPage />
      <Box className={classes.root}>
        <Box data-id={User?.Id} className={classes.user_box}>
          <Box className={classes.user_box_avatar}>
            <UserAvatar user={User} src={User?.Avatar} size={100} />

            <Box>
              <Text ta="center" weight={400} size={20} mt={16} mb={16}>
                {t('hello')}, {User?.FirstName} {User?.MiddleName}
              </Text>
            </Box>
            <Box className={classes.user_box_buttons}>
              <Button variant="default" data-value="profile" onClick={handleClickListItem}>
                {t('goToProfile')}
              </Button>
              <Button variant="default" data-value="logout" onClick={handleClickListItem}>
                {t('exit')}
              </Button>
            </Box>
          </Box>
          <Box className={classes.menu}>
            {menu.length > 0 && (
              <Menu transitionProps={{ transition: 'fade', duration: 150 }}>
                {menu.map(
                  (item, index) =>
                    item.url &&
                    item.label && (
                      <Menu.Item
                        className={classes.menu_item}
                        key={index}
                        component="a"
                        href={item.url}
                        fz={14}
                        icon={
                          item.iconSrc ? (
                            <Box component="img" src={item.iconSrc} height={48} mt={6} mb={6} />
                          ) : (
                            <Image height={48} mt={6} mb={6} src={null} withPlaceholder />
                          )
                        }
                      >
                        <Text weight={400} size={16}>
                          {item?.label}
                        </Text>
                      </Menu.Item>
                    )
                )}
              </Menu>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        onClose={handleCloseAccessCard}
        opened={opened}
        withCloseButton={false}
        size="lg"
        centered={true}
      >
        <NoAccessModuleCard
          iconSrc={fromValue?.iconSrc}
          moduleTitle={fromValue?.moduleTitle}
          handleClose={handleCloseAccessCard}
        />
      </Modal>
    </Box>
  );
};
