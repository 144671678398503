import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NotFound } from '@components/not-found';
import { Login, Register, Filials, Users, Roles, Profile, Dashboard } from '../pages';
import { UserLink } from '../pages/users/components/modals/link';
import { LinkList } from '../pages/users/components/modals/link-list';
import { ROUTES } from './constants';
import { Statistics } from '../pages/statistics';
import FilialEdit from '../pages/filials/edit';
import FilialSettings from '../pages/filials/edit/components/tabs/settings';
import FilialSecurity from '../pages/filials/edit/components/tabs/security';
import FilialSMTP from '../pages/filials/edit/components/tabs/smtp';
import FilialLDAP from '../pages/filials/edit/components/tabs/ldap';
import RequireAuth from './RequireAuth';
import MainPageRote from './MainPageRote';
import Activity from '../pages/statistics/components/tabs/activity';
import DiskSpace from '../pages/statistics/components/tabs/disk-space';
import Audit from '../pages/statistics/components/tabs/audit';
import EmailModule from '../pages/filials/edit/components/tabs/email';
import CalendarModule from '../pages/filials/edit/components/tabs/calendar';
import DrawModule from '../pages/filials/edit/components/tabs/draw';
import { CreateUser } from '../pages/user/feature/create-user';
import RedirectIFAuth from './RedirectIfAuth';
import { ResetPassword } from '@/containers/pages/reser-Password';
import { RequestResetPassword } from '@/containers/pages/requestResetPassword';
import MailServersPage from '../pages/filials/edit/components/tabs/mail-servers';
import MailingSetupPage from '@/containers/pages/filials/edit/components/tabs/mailing-setup';
import { ConfirmEmail } from '../pages/confirmEmail';
import PersonalData from '@/containers/pages/user/feature/edit-user/components/tabs/personalData';
import FilialData from '@/containers/pages/user/feature/edit-user/components/tabs/filialData';
import Safety from '@/containers/pages/user/feature/edit-user/components/tabs/safety';
import MailAccount from '@/containers/pages/user/feature/edit-user/components/tabs/mailAccount';
import DiskData from '@/containers/pages/user/feature/edit-user/components/tabs/diskData';
import ProfilePageRoute from './ProfilePageRoute';
import { useAppSelector } from '@hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { InviteRegister } from '@/containers/pages/invite-register';
import InviteLinksList from '@/containers/pages/filials/edit/components/tabs/invite-links';
import DiskSpasePage from '@/containers/pages/filials/edit/components/tabs/disk-space';
import IntegrationsPage from '@/containers/pages/commonSettings/tabs/integrations';
import CommonSettingsPage from '../pages/commonSettings';
import BrandingPage from '@/containers/pages/commonSettings/tabs/branding';
import { isFeature } from '@utils/feature';
import ContactsModule from '@/containers/pages/filials/edit/components/tabs/contacts';
import ProjectsModule from '@/containers/pages/filials/edit/components/tabs/projects';
import ProductNotificationsPage from '../pages/commonSettings/tabs/notifications';
import UserSettings from '@/containers/pages/user/feature/edit-user/components/tabs/settings';
import { Activations } from '@/containers/pages/activations';

const isPreviewBuild = import.meta.env.VITE_PREVIEW_BUILD === 'true';
const previewUUID = import.meta.env.VITE_PREVIEW_UUID;
const basenameOption = isPreviewBuild ? `/${previewUUID}/` : '';

export const MainRoutes = () => {
  const { Settings } = useAppSelector((state) => state.userInfo);
  return (
    <BrowserRouter basename={basenameOption}>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<MainPageRote />}>
            <Route path={ROUTES.users.fullPath} element={<Users />}>
              <Route path={ROUTES.users.links.fullPath} element={<LinkList />} />
              <Route path={ROUTES.users.links.createLink.fullPath} element={<UserLink />} />
              <Route path={ROUTES.users.links.link.fullPath} element={<UserLink />} />
            </Route>
          </Route>

          <Route path="/create-user" element={<CreateUser />} />

          <Route path="/profile" element={<ProfilePageRoute />} />
          <Route path={ROUTES.profile.fullPath} element={<Profile />}>
            <Route path={ROUTES.profile.pages.personalData} element={<PersonalData />} />
            <Route path={ROUTES.profile.pages.filials} element={<FilialData />} />
            <Route path={ROUTES.profile.pages.safety} element={<Safety />} />
            <Route path={ROUTES.profile.pages.mailAccount} element={<MailAccount />} />
            <Route path={ROUTES.profile.pages.disk} element={<DiskData />} />
            {isFeature() && (
              <Route path={ROUTES.profile.pages.settings} element={<UserSettings />} />
            )}
          </Route>
          <Route path={ROUTES.commonSettings.fullPath} element={<CommonSettingsPage />}>
            {isFeature() && (
              <Route path={ROUTES.commonSettings.pages.branding} element={<BrandingPage />} />
            )}
            {isSaaS(Settings) && (
              <Route
                path={ROUTES.commonSettings.pages.notifications}
                element={<ProductNotificationsPage />}
              />
            )}
            <Route path={ROUTES.commonSettings.pages.integrations} element={<IntegrationsPage />} />
            {/*<Route path={ROUTES.commonSettings.pages.customisation} element={<Customization />} />*/}
          </Route>
          <Route path={ROUTES.dashboard.fullPath} element={<Dashboard />} />

          <Route path={ROUTES.roles.fullPath} element={<Roles />} />
          {isFeature() && isSaaS(Settings) && (
            <Route path={ROUTES.activations.fullPath} element={<Activations />} />
          )}
          <Route path={ROUTES.filials.fullPath}>
            <Route index element={<Filials />} />
            <Route path={ROUTES.filials.edit.path} element={<FilialEdit />}>
              <Route index element={<FilialSettings />} />
              <Route
                path={ROUTES.filials.edit.pages.common.security}
                element={<FilialSecurity />}
              />
              <Route path={ROUTES.filials.edit.pages.common.smtp} element={<FilialSMTP />} />
              <Route
                path={ROUTES.filials.edit.pages.common.mailServers}
                element={<MailServersPage />}
              />
              <Route
                path={ROUTES.filials.edit.pages.common.mailingSetup}
                element={<MailingSetupPage />}
              />
              <Route
                path={ROUTES.filials.edit.pages.common.diskSpace}
                element={<DiskSpasePage />}
              />
              {/*<Route*/}
              {/*  path={ROUTES.filials.edit.pages.common.importUsers}*/}
              {/*  element={<FilialImportUsers />}*/}
              {/*/>*/}
              <Route path={ROUTES.filials.edit.pages.auth.ldap} element={<FilialLDAP />} />
              <Route path={ROUTES.filials.edit.pages.auth.invite} element={<InviteLinksList />} />
              <Route path={ROUTES.filials.edit.pages.modules.email} element={<EmailModule />} />
              <Route
                path={ROUTES.filials.edit.pages.modules.calendar}
                element={<CalendarModule />}
              />
              <Route path={ROUTES.filials.edit.pages.modules.draw} element={<DrawModule />} />
              {isSaaS(Settings) && (
                <Route
                  path={ROUTES.filials.edit.pages.modules.projects}
                  element={<ProjectsModule />}
                />
              )}

              <Route
                path={ROUTES.filials.edit.pages.modules.contacts}
                element={<ContactsModule />}
              />
            </Route>
          </Route>
          <Route path={ROUTES.statistics.fullPath} element={<Statistics />}>
            <Route path={ROUTES.statistics.links.activity} element={<Activity />} />
            <Route path={ROUTES.statistics.links.diskSpace} element={<DiskSpace />} />
            <Route path={ROUTES.statistics.links.audit} element={<Audit />} />
          </Route>
        </Route>
        {isSaaS(Settings) && (
          <Route path="/register" element={<RedirectIFAuth />}>
            <Route path="/register" element={<Register />} />
          </Route>
        )}
        {/*{isFeature() && (*/}
        <Route path="/register/:code" element={<RedirectIFAuth />}>
          <Route path="/register/:code" element={<InviteRegister />} />
        </Route>
        {/*)}*/}
        <Route path="/login" element={<RedirectIFAuth />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/confirm-email/:code" element={<ConfirmEmail />} />
        <Route path="/password-reset/:code" element={<ResetPassword />} />
        <Route path="/password-reset" element={<RequestResetPassword />} />
        {/*<Route path="/" element={<Navigate replace to="/login" />} />*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
